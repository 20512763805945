import Axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers/user";
import Cookies from "universal-cookie";
const cookies = new Cookies(window.document.cookie);
export const getSalesTips = async ({ featureKey }) => {
  const accessToken = await getAccessToken();
  try {
    const response = await Axios({
      url: `${config.base_url}/api/getSalesTipData`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        FeatureKeyId: featureKey,
      }),
    });
    const responseBody = await response.data;
    return {
      error: null,
      data: responseBody.Data,
    };
  } catch (error) {
    return {
      error:
        (error.response && error.response.data.error) ||
        "Something went wrong!",
      data: [],
    };
  }
};
export const downloadLeaderboardList = async (year) => {
  const accessToken = await getAccessToken();
  try {
    const response = await Axios({
      url: `${config.base_url}/api/downloadLeaderboardData`,
      //url: `https://xyxctgjuca.execute-api.us-east-2.amazonaws.com/dev/TrackBankerRewardsDownload`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        Pbu: cookies.get("portbu") || "",
        Oeid: cookies.get("oeid") || "",
        Year:year,
      }),
    });

    //  const responseBody = {
    //     "Data": [
    //     ]
    // };

    const responseBody = await response.data;
    return {
      error: null,
      data: responseBody.Data,
    };
  } catch (error) {
    return {
      error:
        (error.response && error.response.data.error) ||
        "Something went wrong!",
      data: [],
    };
  }
};
export const downloadElitesRankFile = async () => {
  const accessToken = await getAccessToken();
  try {
    const response = await Axios({
      url: `${config.base_url}/api/getSignedUrlForElitesRankFile`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        Pbu: cookies.get("portbu") || "",
        Oeid: cookies.get("oeid") || "",
      }),
    });
    const responseBody = await response.data;
    return {
      error: null,
      data: responseBody || null,
    };
  } catch (error) {
    return {
      error:
        (error.response && error.response.data.error) ||
        "Something went wrong!",
      data: null,
    };
  }
};
export const storeActivityFeed = async (portBu,officierId,ActivityTypeId) => {
  const accessToken = await getAccessToken();
  try {
    const response = await Axios({
      url: `${config.base_url}/api/storeActivityFeed`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        Pbu: portBu || cookies.get("portbu") || "",
        Oeid: officierId || cookies.get("oeid") || "",
        ActivityTypeId:ActivityTypeId || 1,
      }),
    });
    const responseBody = await response.data;
    return {
      error: null,
      data: responseBody || null,
    };
  } catch (error) {
    return {
      error:
        (error.response && error.response.data.error) ||
        "Something went wrong!",
      data: null,
    };
  }
};