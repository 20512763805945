import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import "./styles.scss";

const FeatureWalkthrough = ({
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
  size,
  isLastStep,
}) => {
  useEffect(() => {
    document.querySelectorAll("button[title]").forEach(function (button) {
      button.removeAttribute("title");
    });
    return () => {};
  }, []);
  return (
    <>
      <Card
        {...tooltipProps}
        style={{
          outline: 0,
          background: "#ffffff",
          width: "380px",
          borderRadius: "0px",
          height: "auto",
        }}
      >
        <CardContent>
          <Grid style={{ paddingLeft: "2px", paddingRight: "6px" }} container>
            <Grid item sm={12}>
              <Grid container spacing={4}>
                <Grid item sm={2}>
                  <Typography
                    style={{
                      color: "#084BB7",
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "26px",
                    }}
                  >
                    {index + 1}/{size}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={10}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  {index < 8 && (
                    <Tooltip
                      title={
                        <div className="skip-text">
                          {" "}
                          Click to exit the tour and explore on your own.{" "}
                        </div>
                      }
                      arrow={true}
                      placement="right"
                      classes={{
                        tooltip: "skipTooltip",
                      }}
                    >
                      <Button
                        className="Skip_Button"
                        size="medium"
                        style={{
                          color: "#FFFFFF",
                          background: "#FFF",
                          display: "flex",
                          border: "0px solid #FFFFFF",
                          padding: "0 23px",
                          minWidth: "auto",
                          maxWidth: "2px",
                          marginRight: "-17px",
                          height: "30px",
                        }}
                        {...skipProps}
                      >
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: 600,
                            fontSize: "14px",
                            display: "flex",
                          }}
                        >
                          Skip
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ marginTop: "4px" }} item sm={12}>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                {step.title}
              </Typography>
              <Typography style={{ fontSize: "16px", marginTop: "4px" }}>
                {step.content}
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: "10px",
              }}
            >
              {index > 0 && (
                <Button
                  className="Back_Button"
                  size="small"
                  style={{
                    fontWeight: 600,
                    marginRight: "10px",
                    fontSize: "16px",
                    width: "72px",
                    height: "44px",
                    background: "#E6E6E6",
                  }}
                  {...backProps}
                >
                  Back
                </Button>
              )}
              {!isLastStep && (
                <Button
                  className="Next_Button"
                  size="small"
                  style={{
                    background: "#0558FF",
                    color: "#ffffff",
                    fontWeight: 600,
                    width: "70px",
                    height: "44px",
                    fontSize: "16px",
                    marginLeft: "4px",
                  }}
                  {...primaryProps}
                >
                  Next
                </Button>
              )}
              {isLastStep && (
                <Button
                  className="getStartedNow_Button"
                  size="small"
                  style={{
                    background: "#0558FF",
                    color: "#ffffff",
                    fontWeight: 600,
                    fontSize: "16px",
                    height: "44px",
                    marginLeft: "4px",
                  }}
                  {...primaryProps}
                >
                  Get Started Now
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default FeatureWalkthrough;
