import React, { useMemo } from "react";
import LeaderboardRankingCard from "./LeaderboardRankingCard";
import LeaderboardTable from "./LeaderboardTable";
import { accessRights } from "../../../config/accessRights";
import {
  CardContent,
  Typography,
  Box,
  Card,
  Tooltip,
  Tabs,
  Tab,
  CircularProgress,
  Button
} from "@material-ui/core";
import SelectDropdown from "../../SelectDropdown";
import { AlertDialog } from "~/components/Dialogs";
import { InfoOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
import CustomDropdown from "../../../components/CustomDropdown";
import { downloadLeaderboardList,storeActivityFeed } from "~/redux/helpers/dashboard.js";
import Cookies from "universal-cookie";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const cookies = new Cookies(window.document.cookie);
const Leaderboard = ({
  selectedTab,
  promoFilterOption,
  leaderboardSelectList,
  selectedOption,
  handleLeaderboardFilter,
  handleLeaderBoardTabs,
  isEnabled,
}) => {
  const LeaderboardTableData = useSelector(
    (state) => state.leaderboard.leaderboard.leaderboardTableData
  );

  const [allPromOptn, setAllPromOptn] = React.useState(
    leaderboardSelectList[0]
  );
  const [downLoadYear, setdownLoadYear] = React.useState(
     new Date().getFullYear()
  );
  const [downloadProgress, setDownloadProgress] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const ShowMaskInfo = useMemo(() => {
    if (selectedTab === 1) {
      return false;
    } else
      return (
        LeaderboardTableData && LeaderboardTableData.bankersNameMasked == 1
      );
  }, [selectedTab, LeaderboardTableData]);
  const handleAllPromotionChange = (value) => {
    const selectedOptions = leaderboardSelectList.find(
      (item) => item.value === value
    );
    setAllPromOptn(selectedOptions);
    handleLeaderboardFilter(selectedOptions.value);
  };
  const handleYearChange = (value) => {
    setdownLoadYear(value);
  };
  const handleError = (errorMsg) => {
    setErrorDialog(true);
  };
  const handleDownloadleaderboard = async (e) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileName = `Rewards_Leaderboard_${downLoadYear}`;
    const fileExtension = ".xlsx";
    storeActivityFeed(cookies.get("portbu"),cookies.get("oeid"),1);
    try {
      setDownloadProgress(true);
      // Simulated API call - replace this with your `downloadLeaderboardList` function
      const response = await downloadLeaderboardList(downLoadYear);
      if (response.error) {
        setDownloadProgress(false);
        handleError();
      }
      if (response.data && response.data.length) {
        const workbook = { Sheets: {}, SheetNames: [] };
        const fieldsToExclude = ["PromotionId", "PromotionName", "fPromotionName"];
        response.data.forEach((dataset, i) => {
          // Filter fields to exclude from each dataset
          const filteredDataset = dataset.map((item) => {
            const filteredItem = {};
            Object.keys(item).forEach((key) => {
              if (!fieldsToExclude.includes(key)) {
                filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
          const sheetName = dataset[0].PromotionName
            ? `${dataset[0].PromotionName}`
            : `Sheet${i}`;
          const worksheet = XLSX.utils.json_to_sheet(filteredDataset);
          workbook.SheetNames.push(sheetName);
          workbook.Sheets[sheetName] = worksheet;
        });

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);

        setDownloadProgress(false);
      } else {
        setDownloadProgress(false);
        handleError("No Records to download");
      }
    } catch (error) {
      setDownloadProgress(false);
      handleError(error.message);
    }
  };
  const yearList= [new Date().getFullYear(),new Date().getFullYear()-1]
  return (
    <>
      <Card
        style={{
          background: "#FFFFFF",
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <CardContent style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexGrow: 1 }}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "24px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Leaderboard
              <Tooltip
                title={
                  <div className="textTooltip">
                    The earnings displayed are the amounts earned in the current
                    year (beginning Jan 1st)
                  </div>
                }
                arrow={true}
                placement="bottom"
                classes={{
                  tooltip: "iconTooltip",
                  arrow: "iconArrow",
                }}
              >
                <InfoOutlined fontSize="small" style={{ marginLeft: 4 }} />
              </Tooltip>
            </Typography>
            <Typography
              style={{
                marginRight: "5px",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              See how you match up against your peers based on rewards earned.
            </Typography>
          </div>
          {/* <SelectDropdown
            selectList={leaderboardSelectList}
            selectedValue={selectedOption}
            onSelectChange={handleLeaderboardFilter}
          /> */}

   
<CustomDropdown
            selectedOption={allPromOptn}
            id="all_promotion"
            menuWidth="225px"
            menuHeight="auto"
            align="bottom"
            menuPosition={{
              right: "0",
              top: "110%",
              fontSize: "14px",
              fontWeight: 500,
              color: "#084BB7",
              height: leaderboardSelectList.length > 5 ? "185px" : "auto",
              overflowY: leaderboardSelectList.length > 5 ? "scroll" : "none",
              overflowX: "hidden",
            }}
            buttonStyle={{
              backgroundColor:
                allPromOptn.item === "All Promotions" ? "#E6E6E6" : "#0558FF",
              color: allPromOptn.item === "All Promotions" ? "#000" : "#FFF",
              padding: "8px 25px 8px 16px",
              fontSize: "16px",
              height: "44px",
              width: "158px",
              fontWeight: 600,
              zIndex: 10,
            }}
            iconStyle={{
              right: "4px",
              top: "55%",
              transform: "translateY(-50%)",
            }}
          >
            {leaderboardSelectList.map((option) => (
              <li
                key={option.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  color: "#084BB7",
                  backgroundColor:
                    option.value === allPromOptn.value ? "null" : "transparent",
                  textDecoration:
                    option.value === allPromOptn.value ? "underline" : "none",
                }}
                onMouseEnter={(e) => {
                  if (option.value !== allPromOptn.value) {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.textDecoration = "underline";
                  }
                }}
                onMouseLeave={(e) => {
                  if (option.value !== allPromOptn.value) {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.textDecoration = "none";
                  }
                }}
                onClick={() => handleAllPromotionChange(option.value)}
              >
                {option.item}
              </li>
            ))}
          </CustomDropdown>
        </CardContent>
        <Tabs
          value={selectedTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleLeaderBoardTabs}
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            marginLeft: "13px",
            marginRight: "15px",
            // boxSizing:"border-box"
          }}
        >
          <Tab
            label={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  fontSize: "14.5px",
                  fontWeight: selectedTab !== 1 ? 600 : 400,
                  color: selectedTab !== 1 ? "#0558FF" : "#000",
                }}
              >
                My FI Ranking
                <Tooltip
                  title={
                    <div className="textTooltip">
                      See your position against your peers in the same financial
                      institution
                    </div>
                  }
                  arrow={true}
                  placement="bottom"
                  classes={{
                    tooltip: "iconTooltip",
                    arrow: "iconArrow",
                  }}
                >
                  <InfoOutlined
                    fontSize="small"
                    style={{ marginLeft: 4, color: "#000" }}
                  />
                </Tooltip>
              </div>
            }
            style={{
              textTransform: "none",
              color: "black",
              fontWeight: "bold",
            }}
          />
          <Tab
            label={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  fontSize: "14.5px",
                  fontWeight: selectedTab === 1 ? 600 : 400,
                  color: selectedTab === 1 ? "#0558FF" : "#000",
                }}
              >
                My Earnings Tier
                <Tooltip
                  title={
                    <div className="textTooltip">
                      View your tier placement based on total rewards earned and
                      view your ranking among employees from all Elan Financial
                      Institutions.
                    </div>
                  }
                  arrow={true}
                  placement="bottom"
                  classes={{
                    tooltip: "iconTooltip hgt90px",
                    arrow: "iconArrow",
                  }}
                >
                  <InfoOutlined
                    fontSize="small"
                    style={{ marginLeft: 4, color: "#000" }}
                  />
                </Tooltip>
              </div>
            }
            style={{
              textTransform: "none",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </Tabs>
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            background: "#ffffff",
          }}
        >
          <LeaderboardRankingCard selectedTab={selectedTab} />
        </CardContent>
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            background: "#ffffff",
            height: "260px",
            fontSize: "14px",
            fontWeight: 600,
            marginBottom: "5px",
          }}
        >
          <LeaderboardTable
            selectedTab={selectedTab}
            filterOption={promoFilterOption}
          />
        </CardContent>
        {(isEnabled === accessRights["BANKER_MANAGER"] ||
              isEnabled === accessRights["SENIOR_MANAGER"]) &&    <CardContent
          style={{
            display: "flex",
            flexDirection: "row", // Align items horizontally
            alignItems: "center",
            marginTop: "0.5rem",
            gap: "0.5rem", // Adjust gap between items as needed
          }}
        >
          <div style={{ display: "flex", gap: "1rem" }}>
            {/* Dropdown Component */}
            <div style={{
                zIndex: 1, position: "absolute", width: "180px",
                height: "44px",}}>
          
              <CustomDropdown
                selectedOption={`${downLoadYear} Leaderboard`}
                id="download_year"
                menuWidth="225px"
                menuHeight="auto"
                align="bottom"
                menuPosition={{ left: "0", top: "110%", fontSize: "14px",
                  fontWeight: 500,
                  color: "#084BB7",
                  height: "auto",
                  overflowY: "none",
                  overflowX: "hidden", }}
                buttonStyle={{
                  backgroundColor:
                    downLoadYear === 2025 ? "#E6E6E6" : "#0558FF",
                  color: downLoadYear === 2025 ? "#000" : "#FFF",
                  padding: "8px 25px 8px 16px",
                  width: "180px",
                  height: "44px",
                  fontSize: "14px",
                  fontWeight: 600,
                  // zIndex: 10,
                }}
                iconStyle={{
                  right: "4px",
                  top: "55%",
                  transform: "translateY(-50%)",
                }}
              >
                {yearList.map((option) => (
                  <li
                    key={"leaderboard_download"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      cursor: "pointer",
                      color: "#084BB7",
                      backgroundColor:
                        option === downLoadYear ? "null" : "transparent",
                      textDecoration:
                        option === downLoadYear ? "underline" : "none",
                      // textDecoration: "underline",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                      e.currentTarget.style.textDecoration = "underline";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                      e.currentTarget.style.textDecoration = "none";
                    }}
                    onClick={() => handleYearChange(option)}
                  >
                    {`${option} Leaderboard`}
                  </li>
                ))}
              </CustomDropdown>
            
</div>
            {/* Download Button */}
            <div style={{
                paddingLeft:"200px",
                width: "180px",
                height: "44px"}}>
          
              {downloadProgress ? (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <Button
                  style={{
                    padding: "10.3px 50px",
                    // width: "140px",
                    // height: "44px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    // marginTop: "10px",
                    // marginRight: "50px",
                    position: "relative",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                    border: "2px solid #E6E6E6",
                    background: "#E6E6E6",
                  }}
                  onClick={() => handleDownloadleaderboard()}
                  onMouseLeave={(e) => {
                    e.target.style.background = "#E6E6E6";
                    e.target.style.border = "2px solid #E6E6E6";
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.background = "#fff";
                    e.target.style.border = "2px solid #000";
                  }}
                >
                  Download
                </Button>
              )}
              </div>
          </div>
        </CardContent>}
        {ShowMaskInfo && (
          <CardContent>
            <Box
              style={{
                background: "#E6E6E6",
                display: "flex",
                alignItems: "center",
                padding: "2px",
                gap: "8px",
                height: "35px",
              }}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "21px",
                  marginLeft: "10px",
                }}
              >
                *Employee names are masked for privacy.
              </Typography>
            </Box>
          </CardContent>
        )}
        {errorDialog && (
          <AlertDialog
            title={"OOPS!"}
            message={
              "Something went wrong, We're working on getting this fixed as soon as we can."
            }
            onConfirm={() => {
              setErrorDialog(false);
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Leaderboard;
