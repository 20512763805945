import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  MobileStepper,
  Typography,
  useTheme,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { getSalesTips } from "../../../../redux/helpers/dashboard";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#E6E6E6",
  },
  dot: {
    backgroundColor: "#0558FF80",
  },
  dotActive: {
    backgroundColor: "#0558FF",
    width: "20px",
    borderRadius: "5px",
  },
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function CalculatorSalesTips() {
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [salesTips, setSalesTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxSteps = salesTips.length;

  useEffect(() => {
    const fetchSalesTips = () => {
      getSalesTips({ featureKey: 2 }).then((res) => {
        if (res) {
          setSalesTips(res.data);
          setLoading(false);
        }
      });
    };
    fetchSalesTips();
  }, []);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            interval={20000}
          >
            {salesTips.map((tip, index) => (
              <div key={tip.title}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Card
                    style={{
                      background: "#E6E6E6",
                      height: "490px",
                      borderRadius: "0",
                      boxSizing: "border-box",
                      marginLeft: "2px",
                      marginRight: "10px",
                      marginTop: "10px",

                    }}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position:'relative'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "24px",
                          fontWeight: 500,
                        }}
                      >
                        Sales Tips
                      </Typography>
                      <img src={tip.IconURL} />
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: 600,
                          marginTop: "5px",
                          // marginTop: "12px",
                        }}
                      >
                        {tip.Heading}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          marginTop: "5px",
                        }}
                      >
                        {tip.SubHeading}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          position:"absolute",
                          left:'10px',
                          height:"440px"
                        }}
                      >
                        <MobileStepper
                          classes={{
                            root: classes.root,
                            dot: classes.dot,
                            dotActive: classes.dotActive,
                          }}
                          steps={maxSteps}
                          position="static"
                          activeStep={activeStep}
                        />
                      </div>
                    </CardContent>
                  </Card>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </>
      )}
    </>
  );
}

export default CalculatorSalesTips;
